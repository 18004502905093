import React, { ComponentProps } from 'react';
import styled, { css } from 'styled-components';
import cn from 'classnames';
import Picture from '../../components/Picture';
import { customMediaQuery } from '../../styled';
// TODO move icon from member>home to bundles licences assets
import licenceDefaultIcon from '../../../pages/Member/components/home/assets/license_default_icon.svg';

type tProps = {
  src: string | null;
  className?: string;
} & ComponentProps<typeof Picture>;

const LicenseAvatar: React.FC<tProps> = ({ src, className, ...props }) => {
  return (
    <SPicture
      lazy
      src={src}
      fallbackImage={licenceDefaultIcon}
      className={cn(className)}
      alt=""
      {...props}
    />
  );
};

export default LicenseAvatar;

const mobileSizes = css`
  width: 48px;
  height: 48px;
`;

const tabletSizes = css`
  width: 52px;
  height: 52px;
`;

const SPicture = styled(Picture)`
  border-radius: 50%;
  ${mobileSizes}

  ${customMediaQuery('tablet')} {
    ${tabletSizes}
  }
`;
