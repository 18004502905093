import React from 'react';
import styled from 'styled-components';
import { Translate } from 'i18n';

type tWithBoardsLableProps = {
  className?: string;
  icon: React.ReactNode;
};

const WithBoardsLable: React.FC<tWithBoardsLableProps> = ({ className, icon }) => {
  return (
    <SWithLabelBlock className={className}>
      {icon}
      <Translate str="frontend.licenses.boards_included" />
    </SWithLabelBlock>
  );
};

const SWithLabelBlock = styled.div`
  display: flex;
  font-size: 0.875rem;

  &.with_assignment-label_block--isExpired {
    color: var(--heading-text-color-1);
  }
`;

export default WithBoardsLable;
