import { tClientLicence } from '@adeptlms/lingu-students-react-shared';
import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import UrlIcon from '../../../../components/UrlIcon';
import { customMediaQuery } from '../../../../styled';
import LicenseAvatar from '../../LicenseAvatar';
import checkIcon from '../../assets/check_icon.svg';
import WithAssignmentsLabel from '../WithAssignmentsLabelBlock';
import WithBoardsLable from '../WithBoardsLable';

type tProps = {
  license: NonNullable<tClientLicence>;
  className?: string;
  nameClassName?: string;
  statusClassName?: string;
  withAssignmentLabel?: boolean;
  widthBoardLabel?: boolean;
  withContactEmail?: boolean;
  withTeamName?: boolean;
};
const LicenceLabel: React.FC<tProps> = ({
  license,
  className,
  nameClassName,
  statusClassName,
  withAssignmentLabel = false,
  widthBoardLabel = false,
  withContactEmail = false,
  withTeamName
}) => {
  // const { computed } = licence;
  // const status = useMemo(() => {
  //   if (computed.status === 'unlimited') {
  //     // TODO move translations to licenses
  //     return <Translate str="frontend.member.home_page.unlimited" />;
  //   } else if (computed.status === 'inactive') {
  //     return <Translate str="frontend.member.home_page.inactive_license" />;
  //   } else {
  //     return (
  //       <>
  //         👆
  //         <Translate
  //           str="frontend.member.home_page.days_left"
  //           params={{
  //             days: computed.daysLeft
  //           }}
  //         />
  //       </>
  //     );
  //   }
  // }, [computed]);

  const showAssignment = withAssignmentLabel && license.allowsAssignment;

  const showBoard = widthBoardLabel && license.boardsEnabled;
  const isExpired = license.computed.status === 'inactive';

  return (
    <SLicenceLabelWrapper className={cn(className)}>
      <SLicenceRow>
        <div className="ln-flex-shrink-0">
          <LicenseAvatar src={license.teamImageURL} />
        </div>

        <SLicenceTextColumn>
          {withTeamName ? (
            <SLicenceName
              title={license.teamName}
              className={cn(nameClassName)}
              style={{ marginBottom: 0 }}
            >
              {license.teamName}
            </SLicenceName>
          ) : (
            <SLicenceName title={license.name || ''} className={cn(nameClassName)}>
              {license.name || ''}
            </SLicenceName>
          )}

          <SStatusBlock>
            {withContactEmail ? (
              <SStatus className={cn(statusClassName)}>
                {license.teamContactEmail}
              </SStatus>
            ) : (
              <SStatus>{license.name || ''}</SStatus>
            )}
          </SStatusBlock>
        </SLicenceTextColumn>
      </SLicenceRow>
      {showBoard || showAssignment ? (
        <SStatusBlock className="ln-pt-4">
          {showAssignment ? (
            <WithAssignmentsLabel
              isExpired={isExpired}
              className={cn(statusClassName)}
              icon={<SCheckIcon color="white" />}
            />
          ) : null}

          {showBoard ? <WithBoardsLable icon={<SCheckIcon color="white" />} /> : null}
        </SStatusBlock>
      ) : null}
    </SLicenceLabelWrapper>
  );
};

export default LicenceLabel;

const SLicenceLabelWrapper = styled.div`
  background: transparent;
  border-radius: 20px;
  color: var(--heading-text-color);
  color: var(--color-white);
`;
const SLicenceRow = styled.div`
  display: flex;
`;
const SLicenceTextColumn = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
  justify-content: center;
  min-width: 0;
  padding-inline: 8px;
`;

const SLicenceName = styled.span`
  color: var(--heading-text-color);
  color: var(--color-white);
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 600;
  padding: 0;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  ${customMediaQuery('tablet')} {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
`;

const SStatusBlock = styled.div`
  display: flex;
  item-align: center;
  flex-direction: column;

  ${customMediaQuery('tablet')} {
    flex-direction: row;

    & > * + *::before {
      content: ' • ';
      padding: 0 0.5rem;
      color: var(--heading-text-color-1);
      opacity: 0.4;
      display: flex;
      align-items: center;
    }
  }
`;

const SStatus = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  opacity: 80%;

  &.active {
    color: var(--color-yellow);
  }

  &.inactive {
    color: var(--color-red);
  }
`;

const SCheckIcon = styled(UrlIcon).attrs({ url: checkIcon })`
  min-width: 16px;
  min-height: 16px;
  margin-inline-end: 4px;

  ${customMediaQuery('tablet')} {
    min-width: 20px;
    min-height: 20px;
  }
`;
