import React from 'react';
import styled from 'styled-components';
import { Translate } from 'i18n';
import cn from 'classnames';

type tWithAssignmentsLabelProps = {
  isExpired?: boolean;
  className?: string;
  icon: React.ReactNode;
};
const WithAssignmentsLabel: React.FC<tWithAssignmentsLabelProps> = ({
  isExpired,
  className,
  icon
}) => {
  return (
    <SWithAssignmentsLabelBlock
      className={cn(className, { 'with_assignment-label_block--isExpired': isExpired })}
    >
      {isExpired ? (
        <Translate
          str="frontend.licences.submitting_assignments_were_included"
          params={{ defaultValue: 'Assignments were included' }}
        />
      ) : (
        <>
          {icon}
          <Translate
            str="frontend.licences.submitting_assignments_included"
            params={{
              defaultValue: 'Submitting assignments included'
            }}
          />
        </>
      )}
    </SWithAssignmentsLabelBlock>
  );
};

const SWithAssignmentsLabelBlock = styled.div`
  display: flex;
  font-size: 0.875rem;

  &.with_assignment-label_block--isExpired {
    color: var(--heading-text-color-1);
  }
`;

export default WithAssignmentsLabel;
